
import {
  alertController,
  IonPage,
  IonContent,
  loadingController,
  IonInput,
  IonLabel,
  IonCheckbox,
  IonBackButton,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonHeader,
} from "@ionic/vue";
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Browser } from "@capacitor/browser";
import { Analytics } from "../../../common/analytics";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Register",
  /**
   * components
   */
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonLabel,
    IonCheckbox,
    IonBackButton,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonHeader,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const screenName = "PAGE-REGISTER";
    const analytics = new Analytics();

    const state = reactive({
      form: {
        loginid: "",
        email: "",
        pass: "",
        name: "",
        terms: {
          provision: false,
          privacy: false,
          thirdParty: false,
          maketing: false,
          locationagree: false,
        },
      },
      valEmail: false,
    });

    const emailVal = () => {
      if (state.form.email.includes("@") && state.form.email.includes(".")) {
        state.valEmail = true;
      } else {
        state.valEmail = false;
      }
    };

    const formAllTerms = computed(() => {
      let isCheck = true;
      if (!state.form.terms.provision) {
        isCheck = false;
      }
      if (!state.form.terms.privacy) {
        isCheck = false;
      }
      if (!state.form.terms.thirdParty) {
        isCheck = false;
      }
      if (!state.form.terms.locationagree) {
        isCheck = false;
      }
      if (!state.form.terms.maketing) {
        isCheck = false;
      }

      return isCheck;
    });

    const formAllTermstwo = computed(() => {
      let isCheck = true;
      if (!state.form.terms.provision) {
        isCheck = false;
      }
      if (!state.form.terms.privacy) {
        isCheck = false;
      }
      if (!state.form.terms.thirdParty) {
        isCheck = false;
      }
      if (!state.form.terms.locationagree) {
        isCheck = false;
      }
      return isCheck;
    });

    const presentAlert = async (message: string) => {
      const alert = await alertController.create({
        header: `${t("screen.auth.register.registrationAlarm")}`,
        message: message,
        buttons: [`${t("check")}`],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const onAllCheck = async () => {
      let isCheck = true;
      if (!state.form.terms.provision) {
        isCheck = false;
      }
      if (!state.form.terms.privacy) {
        isCheck = false;
      }
      if (!state.form.terms.thirdParty) {
        isCheck = false;
      }
      if (!state.form.terms.locationagree) {
        isCheck = false;
      }
      if (!state.form.terms.maketing) {
        isCheck = false;
      }

      if (isCheck) {
        state.form.terms.provision = false;
        state.form.terms.privacy = false;
        state.form.terms.thirdParty = false;
        state.form.terms.locationagree = false;
        state.form.terms.maketing = false;
      } else {
        state.form.terms.provision = true;
        state.form.terms.privacy = true;
        state.form.terms.thirdParty = true;
        state.form.terms.locationagree = true;
        state.form.terms.maketing = true;
      }
    };

    const onSubmit = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: `${t("screen.auth.register.registering")}`,
      });
      await loading.present();
      store
        .dispatch("register", {
          loginid: state.form.loginid,
          email: state.form.email,
          pass: state.form.pass,
          name: state.form.name,
          termsAgree: state.form.terms.provision ? 1 : 0,
          privacyAgree: state.form.terms.privacy ? 1 : 0,
          thirdpartyAgree: state.form.terms.thirdParty ? 1 : 0,
          locationAgree: state.form.terms.locationagree ? 1 : 0,
          marketingAgree: state.form.terms.maketing ? 1 : 0,
        })
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            setTimeout(function () {
              loading.dismiss();
              router.push({
                name: "login",
              });
            }, 1000);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        })
        .catch((message) => {
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        });
    };

    const onOpenUrl = async (url: string) => {
      if (url.length) {
        await Browser.open({ url: url });
      }
    };

    watch(
      () => state.form.email,
      () => {
        emailVal();
      }
    );

    onMounted(() => {
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return {
      t,
      router,
      onSubmit,
      onAllCheck,
      onOpenUrl,
      state,
      formAllTerms,
      formAllTermstwo,
    };
  },
});
